import React from 'react';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Banner from './Banner';
import Subtitle from './Subtitle';


const useStyles = makeStyles( theme => ({
  content: {
    margin: "20px auto"
  },
  picture: {
    marginBottom: 10,
    width: "100%",
    [theme.breakpoints.up("sm")]: { 
      maxWidth: 750,
    },
  },
}));


const Articles = (props) => {
  const classes = useStyles();
  const article = props.article;

  const content = {
    construction: [
      {type: "title", text: "Entreprise de construction Groslay, Argenteuil"},
      {type: "content", text: "Votre entreprise de construction à Groslay, Saint-Denis et Argenteuil est spécialisée dans la construction de maison individuelle, avec ou sans maîtrise d'oeuvre (architecte, suivi de chantier), nous prenons le projet à sa naissance sur les plans et pouvons vous le livrer clef en mains ou stopper au hors d'eau (toiture réaliser) ou hors d'air (toiture réalisée et fenêtres posées). Basée à GROSLAY dans l'Est du val d'Oise depuis 1994, la société ABS entreprise reste à votre écoute, n'hésiter pas à nous contacter pour un devis personnalisé"},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/construction.jpg?alt=media&token=ccfc534a-4727-45e6-9e5d-a232a87c7e22" },
      {type: "title", text: "Terrassement"},
      {type: "content", text: "Le terrassement est souvent le démarrage obligé de tous projets de construction, nous le maîtrisons de la fondation simple à l'excavation pour un sous-sol conséquent, nous sommes en mesure de creuser et d'évacuer les terres excédentaires, que ce soit pour une piscine, des aménagements extérieurs ou tous autres projets"},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/construction1.JPG?alt=media&token=9bb32aa5-6fdb-437e-aa1e-3dd72687b366" },
      {type: "title", text: "Maçonnerie"},
      {type: "content", text: "Bien sur ABS, l'entreprise de construction disposant de plusieurs maçons dans le Val d'Oise (Sarcelles, Argenteuil...) est forcément spécialisé dans le domaine de la maçonnerie, nous construisons en plusieurs types de matériaux, à savoir, parpaing classique monté au mortier, brique creuses classique montées au mortier, briques pleines montées au mortier, blocs calcaire et tous type de pierre monté au mortier bloc parpaing joint minces montés à la colle, monomur en terre cuite montée à la colle, bloc de béton cellulaire et tout type de nouveau matériaux porteurs et isolants ainsi que tous type de matériaux BIO, nous sommes à l'écoute pour tous projets sortant de l'ordinaire. Basée à GROSLAY nous intervenons dans tout l'est du Val d'Oise et ses abords, nous consulter"},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/construction2.JPG?alt=media&token=10bf7a69-bec3-4917-a922-335fa543bbfc" },
      {type: "title", text: "Charpente, Couverture"},
      {type: "content", text: "Nous sommes également capables de réaliser des travaux sur votre charpente, qu'elle soit traditionnelle ou industrielle et quel que soit votre type de projet."},
      {type: "content", text: "Votre entreprise de construction à Saint-Denis, Argenteuil et Sarcelles réalise tout types de couverture que ce soit en neuf ou en rénovation et avec tout types de matériaux, tuiles mécaniques, tuiles plates béton ou terre cuite, ardoises naturelles, ardoises fibrociment, zinc, cuivre, shingle, bardeaux bitumeux, étanchéité, bacs aciers, etc."},
    ],
    renovation: [
      {type: "title", text: "Entreprise de rénovation de bâtiments"},
      {type: "content", text: "L'entreprise de rénovation à Groslay dans le Val d'Oise (non loin de Saint-Denis) a fait de la rénovation de maison et de bâtiment sa spécialité. Sa force réside dans la polyvalence de ses équipes et forcément son expérience depuis 20 ans, des centaines de chantier réalisés."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/CLEANrenovation6.jpg?alt=media&token=dce3a38c-7c52-48b7-9987-11430890d173" },
      {type: "content", text: "Qu'importe la taille ou le type de chantier, une maison, un immeuble, une maison de ville à diviser à réaménager, ensemble nous étudions votre projet et vous livrons dans les délais convenus."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/renovation1.JPG?alt=media&token=99c1a95f-1c92-4e27-a734-d0966473f3f2" },
      {type: "title", text: "Solide expérience en matière de rénovation dans le Val d'Oise"},
      {type: "content", text: "La rénovation est un vaste domaine aussi étendu que peut l'être le domaine du batiment et fort de notre expérience, nous sommes en mesure de faire une rénovation complète aussi bien intérieure qu'extérieure. En extérieur nous maitrisons tout type de ravalement que ce soit un ravalement peinture basique ou de type étanchéité, ou bien de façon structurelle comprenant le piochage, armature et la remise en forme des surfaces avec tout type de produits que ce soit de l'enduit monocouche, de l'enduit ciment traditionnel, de l'enduit en mortier de chaux naturelle ou du MPC pour les maisons anciennes construites avec des matériaux à base de plâtre. Dans un style plus actuel nous maîtrisons aussi l'isolation par l'extérieur (ITE) avec la finition au choix du client : enduit type RPE coloré ou tout types de bardage."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/renovation2.JPG?alt=media&token=9f2c7e71-696a-4953-bc11-90c4579f0d0c" },
      {type: "content", text: "Le remplacement des menuiseries fait partie aussi de nos activités, que ce soit des fenêtres, portes, volets, porte de garage en bois, PVC ou alu."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/renovation3.JPG?alt=media&token=2fa325ca-bf47-40e8-9d1f-11e49352b144" },
      {type: "content", text: "La toiture fait partie aussi d'une rénovation, nous la maitrisons de bout en bout ainsi que la charpente si il y a lieu, avec tout types de matériaux, tuiles, ardoises, zinc, cuivre, shinghles, bacs acier, bardeaux bitumeux avec bien sur la création ou le remplacement de fenêtres de toit de toute la gamme VELUX."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/CLEANrenovation4.jpg?alt=media&token=34a0aac8-b154-46de-98d2-e50389bcedc6" },
      {type: "title", text: "Votre entreprise de rénovation intérieure"},
      {type: "content", text: "Une rénovation intérieure passe souvent par une modification des volumes intérieures aussi nous sommes capable d'assurer ce type de chantier en tous corps d'états du début jusqu'au dernier coup de pinceau, avec ou sans maitrise d'oeuvre. Nos équipes maitrise ce type de travaux, c'est à dire dans l'ordre, la démolition, la mise à nu de tout type de supports, la plomberie, l'électricité, le chauffage, l'isolation, le doublage, les cloisons, le revêtement sols et murs, la menuiserie avec la pose d'escalier, de portes intérieures classique et coulissante, la création ou le renforcement de plancher, la domotique, la serrurerie."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/renovation5.jpg?alt=media&token=4d877998-b163-4e61-acf9-83144cf2ee03" },
      {type: "content", text: "Pour toutes demandes, veuilez contacter l'entreprise de rénovation à Groslay non loin de Saint-Denis et Argenteuil via le site internet ou par téléphone"},
    ],
    agrandissement: [
      {type: "title", text: "Entreprise d'extension - Agrandissement de bâtiments"},
      {type: "content", text: "Les extensions et les agrandissements tout comme la construction sont les spécialités de la société ABS entreprise, avec ou sans maîtrise d'oeuvre (architecte, suivi de chantier), nous prenons le projet à sa naissance sur les plans et pouvons vous le livrer clef en mains ou stopper au hors d'eau (toiture réaliser) ou hors d'air ( toiture réalisée et fenêtres posées). Basée à GROSLAY dans l'Est du val d'Oise depuis 1994, la société ABS entreprise est à votre écoute, n'hésiter pas à nous consulter"},
      {type: "picture", name: 1, src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/agrandissement.JPG?alt=media&token=57723e0f-71a2-4980-af55-e7bac4976b20" },
      {type: "title", text: "Terrassement"},
      {type: "title", text: "Maçonnerie"},
      {type: "content", text: "Bien sur ABS entreprise dans le Val d'oise disposant de plusieurs maçons est forcément spécialisé dans le domaine de la maçonnerie, nous construisons en plusieurs types de matériaux, à savoir, parpaing classique monté au mortier, brique creuses classique montées au mortier, briques pleines montées au mortier, blocs calcaire et tous type de pierre monté au mortier bloc parpaing joint minces montés à la colle, monomur en terre cuite montée à la colle, bloc de béton cellulaire et tout type de nouveau matériaux porteurs et isolants ainsi que tous type de matériaux BIO, nous sommes à l'écoute pour tous projets sortant de l'ordinaire. Basée à GROSLAY nous intervenons dans tout l'est du Val d'Oise et ses abords, nous consulter"},
      {type: "content", text: "Les travaux de terrassement est souvent le démarrage obligé de tous projets d'extension, nous le maîtrisons de la fondation simple à l'excavation pour un sous-sol conséquent, nous sommes en mesure de creuser et d'évacuer les terres excédentaires, que ce soit pour une piscine, des aménagements extérieurs ou tous autres projets"},
      {type: "title", text: "Charpente, Couverture"},
      {type: "content", text: "Nous sommes également capables de réaliser votre charpente, qu'elle soit traditionnelle ou industrielle et quel que soit votre type de projet."},
      {type: "content", text: "Nous pouvons réaliser différents types de couverture que ce soit en neuf ou en rénovation et avec tout types de matériaux, tuiles mécaniques, tuiles plates béton ou terre cuite, ardoises naturelles, ardoises fibrociment, zinc, cuivre, shingle, bardeaux bitumeux, étanchéité, bacs aciers, etc."},
      {type: "title", text: "Doublage, isolation, cloisons"},
      {type: "content", text: "Votre entreprise d'agrandissement de maison à Sarcelles (proche de Saint-Denis et Argenteuil) est à même de poser tout type d'isolation (intérieure et extérieure) en conformité avec les nouvelles législation, type RT 2012, BBC ou plus encore."},
      {type: "content", text: "Nous pouvons mettre en oeuvre tous type de doublage, y compris les matériaux BIO respectueux de notre environnement."},
      {type: "content", text: "Pour les cloisons de votre projets, nous pouvons procéder de plusieurs façons, plusieurs épaisseurs, plusieurs matériaux, à vous de choisir."},
      {type: "title", text: "Technique"},
      {type: "content", text: "Nous qualifions ainsi les réseaux et les circuits qui amène le confort dans votre maison. Aussi nous sommes à même de réaliser complètement une installation électrique, une installation de plomberie compris réseaux d'assainissement extérieur, une installation de chauffage ou de complément de chauffage, une installation de salle de bains avec tous ses sanitaires, d'un WC, d'une cuisine complète."},
      {type: "title", text: "Revêtements"},
      {type: "content", text: "Votre entreprise d'agrandissement de maison à Groslay, Sarcelles et Argenteuil (Val d'Oise) est capable de réaliser pour vous et selon vos critères de choix la pose de tout types de carrelage et de faïence, la pose de tout type de placage, de toutes sortes de parquets que ce soit en flottant, collé ou cloué, de manière générale nous sommes compétent dans la réalisation de tout type de décoration, n'hésiter pas à nous consulter."},
    ], 
    surelevation: [
      {type: "title", text: "Entreprise de surélévation de bâtiments"},
      {type: "content", text: "Les surélévations tout comme les extensions ou les constructions sont les spécialités de la L'entreprise de bâtiment ABS à Groslay, avec ou sans maîtrise d'oeuvre (architecte, suivi de chantier), nous prenons le projet à sa naissance sur les plans et pouvons vous le livrer clef en mains ou stopper au hors d'eau (toiture réaliser) ou hors d'air ( toiture réalisée et fenêtres posées)."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/surelevation.JPG?alt=media&token=efdb7f02-f4b8-49e8-bc87-f738d372d36f" },
      {type: "content", text: "Toutefois les surélévations sont plus spécifiques que les extensions, nous avons le plus souvent recours à ce que nous appelons un pare pluie une sorte de toiture provisoire posé sur les échafaudages et qui nous permet de déposer la toiture existante sans se soucier des conditions météorologique."},
      {type: "content", text: "N'hésitez pas, en cas de question en matière de surélévation de maison, à prendre contact avec l'entreprise de bâtiment à Groslay (non loin d'Argenteuil et Montmagny)"},
      {type: "title", text: "Maçonnerie"},
      {type: "content", text: "Bien sur ABS entreprise disposant de plusieurs maçons est forcément spécialisé dans le domaine de la maçonnerie, nous construisons en plusieurs types de matériaux, à savoir, parpaing classique monté au mortier, brique creuses classique montées au mortier, briques pleines montées au mortier, blocs calcaire et tous type de pierre monté au mortier bloc parpaing joint minces montés à la colle, monomur en terre cuite montée à la colle, bloc de béton cellulaire et tout type de nouveau matériaux porteurs et isolants ainsi que tous type de matériaux BIO, nous sommes à l'écoute pour tous projets sortant de l'ordinaire."},
      {type: "content", text: "Basée à GROSLAY nous intervenons dans tout l'est du Val d'Oise et ses abords, nous consulter"},
      {type: "title", text: "Charpente, couvertures"},
      {type: "content", text: "Nous sommes également capables de réaliser votre charpente, qu'elle soit traditionnelle ou industrielle et quel que soit votre type de projet."},
      {type: "content", text: "Nous pouvons réaliser tout types de couverture que ce soit en neuf ou en rénovation et avec tout types de matériaux, tuiles mécaniques, tuiles plates béton ou terre cuite, ardoises naturelles, ardoises fibrociment, zinc, cuivre, shingle, bardeaux bitumeux, étanchéité, bacs aciers, etc."},
      {type: "title", text: "Doublage, isolation, cloisons"},
      {type: "content", text: "Nous sommes à même de poser tout type d'isolation en conformité avec les nouvelles législation, type RT 2012, BBC ou plus encore."},
      {type: "content", text: "Nous pouvons mettre en oeuvre tous type de doublage, y compris les matériaux BIO respectueux de notre environnement."},
      {type: "content", text: "Pour les cloisons de votre projets, nous pouvons procéder de plusieurs façons, plusieurs épaisseurs, plusieurs matériaux, à vous de choisir."},
      {type: "title", text: "Technique"},
      {type: "content", text: "Nous qualifions ainsi les réseaux et les circuits qui amène le confort dans votre maison."},
      {type: "content", text: "Aussi nous sommes à même de réaliser complètement une installation électrique, une installation de plomberie compris réseaux d'assainissement extérieur, une installation de chauffage ou de complément de chauffage, une installation de salle de bains avec tous ses sanitaires, d'un WC, d'une cuisine complète."},
      {type: "title", text: "Revêtements"},
      {type: "content", text: "Nous sommes a même de réaliser pour vous et selon vos critères de choix la pose de tout types de carrelage et de faïence, la pose de tout type de placage, de toutes sortes de parquets que ce soit en flottant, collé ou cloué, de manière générale nous sommes compétent dans la réalisation de tout type de décoration, n'hésiter pas à nous consulter."},
    ],
    combles: [
      {type: "title", text: "Entreprise spécialisée dans l'aménagement de combles"},
      {type: "content", text: "Vue les compétences acquises par ABS entreprise depuis sa création dans le domaines du tous corps d'état, l'aménagement de combles est fort logiquement une de nos spécialités."},
      {type: "picture", name: 1, src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/CLEANcombles.jpg?alt=media&token=9d96b716-7827-409a-8975-2fa4179c6f94" },
      {type: "content", text: "Que ce soit des modifications de charpente, de couverture, de la création de VELUX ou de lucarnes, de travaux d'isolation, de la création de plancher, du cloisonnement, des installations sanitaires, de la pose de carrelage, des travaux de décoration, peinture, revêtement de sols."},
      {type: "content", text: "Nous maîtrisons votre aménagement de combles du projet jusqu'à l'emménagement, n'hésitez pas à nous contacter."},
    ],
    couverture: [
      {type: "title", text: "Entreprise de couverture Groslay"},
      {type: "content", text: "Depuis plus de 20 ans, nous oeuvrons dans le domaine spécifique de la couverture, fort d'équipes spécialisées et d'un encadrement compétent, nous réalisons toutes types de travaux, remplacement de toiture complète en tuiles, ardoises, zinc, shingles, bacs aciers, polytuiles, étanchéité, etc."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/couverture.JPG?alt=media&token=367eaf2a-e532-4f70-9c0d-dd96b34c6edb" },
      {type: "content", text: "Nous intervenons aussi sur tout type de réparations suite à des fuites ou tout autres disfonctionnement, à savoir : remplacement de gouttières en zinc, cuivre et PVC, Etanchéité de cheminée, reprise de solin, réparation, remplacement de noues, de rives, de faîtage."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/CLEANcouverture1.jpg?alt=media&token=a08eca7a-ba81-40f3-bc05-c0562b92f570" },
      {type: "content", text: "Installation, remplacement de VELUX, démoussage."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/couverture%202.JPG?alt=media&token=55ac05ee-2aa2-4e10-aa9b-e6b7c17087b2" },
      {type: "content", text: "Réparation, remplacement et mise en peinture de cache moineaux, queues de vaches, boiseries de toitures."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/couverture3.jpg?alt=media&token=c268b931-516e-4e45-af4a-b96444e273a0" },
      {type: "content", text: "Fourniture et pose d'isolant mince, de film pare pluie fixé par contre lattage"},
    ],
    electricite: [
      {type: "title", text: "Entreprise électricité, plomberie Groslay (Val d'Oise)"},
      {type: "content", text: "Nos techniciens sont à même d'assurer la pose, l'installation, ou la réparation de tout ce qui concerne la partie technique dans votre habitat."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/CLEANelectricite.jpg?alt=media&token=cd62485d-498b-4396-8c78-adfafc5c8b0d" },
      {type: "title", text: "ABS Entreprise propose des prestations en électricité"},
      {type: "content", text: "Nous installons, rénovons et réparons les installations électriques que ce soit de l'habitat ou du tertiaire du 220 V ou 380V en passant par la basse tension utiliser le plus souvent dans l'habitat pour les contrôles d'accès."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/plomberie.JPG?alt=media&token=6b5c1bd4-3c76-4925-82cb-a446e518a929" },
      {type: "content", text: "Nous sommes à même d'intervenir pour des dépannages, des extensions de réseaux, des mises aux normes, dans des ouvrages de réseaux ou colonne ERDF souvent rencontrés en création de nouveaux lots."},
      {type: "title", text: "Compétences en plomberie"},
      {type: "content", text: "En ce qui concerne la plomberie nos équipes sont à même d'intervenir en réparation, en modification ou en installation complète sur réseaux extérieurs, réseaux salle de bains, réseaux cuisine, réseaux évacuation sur de l'habitat individuel ou du collectif."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/plomberie1.JPG?alt=media&token=f7368c8b-e79d-4947-85f1-cae144c404be" },
      {type: "title", text: "Compétences en chauffage"},
      {type: "content", text: "Pour le chauffage nous remplaçons et posons des chaudières à gaz, nous sommes à même de procéder à des extensions sur des circuits eau chaude en tout type de matériaux, que ce soit en cuivre, en acier, en PER (polyéthylène réticulé) ou en multicouche."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/CLEANplomberie2.jpg?alt=media&token=e8df89e2-0859-43a9-864a-ad825c96e2e7" },
      {type: "content", text: "Nous pouvons aussi installer des chauffages au sol basse température que soit en eau chaude relié à une chaudière, ou en électrique genre trame noyé dans la chape type KS de chez THERMOR ou tout autres types de matériaux."},
      {type: "content", text: "N'hésitez pas à nous contacter pour tout type de demande."},
    ],
    isolation: [
      {type: "title", text: "Entreprise d'isolation extérieure et intérieure"},
      {type: "content", text: "ABS, l'entreprise d'isolation à Groslay, Argenteuil et Saint-Denis intervient en matière d'isolation thermique et phonique. Il s'agit d'une activité importante et primordiale lors de la construction d'une maison ou d'un immeuble."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/isolation.JPG?alt=media&token=4de859d7-baa7-4baa-ab06-cc7ab898afdd" },
      {type: "title", text: "Large champs d'intervention"},
      {type: "content", text: "L'entreprise de bâtiment intervient pour différents travaux d'isolation que ce soit l'isolation extérieure (ITE)ou par l'intérieur lors de travaux de rénovation. ABS Entreprise vous propose aussi l'isolation de votre toiture. Cela passe par la pose d'un isolant mince multiréflecteur doté d'un film pare vapeur, qui est couplé d'une épaisseur de laine de verre. Pour ce genre d'intervention, le film est posé sous les tuiles et au-dessus de la charpente."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/isolation1.JPG?alt=media&token=b4794987-9c30-433e-96e5-bc492d93e450" },
      {type: "title", text: "Utilisation de matériaux de qualité"},
      {type: "content", text: "Pour réaliser vos travaux d'isolation dans le Val d'Oise nous utilisons différents types de matériaux; les matériaux classiques de type PSE (polystyrène), de la laine de verre et laine de roche rigide, et d'autres matériaux à base de chanvre, de laine de bois et autres matériaux bio et innovants."},
      {type: "content", text: "Pour toutes demandes, n'hésitez pas à prendre contact avec ABS, votre entreprise d'isolation à Groslay, Saint-Denis et Argenteuil via le formulaire mis à votre disposition."},
    ],
    menuiserie: [
      {type: "title", text: "Entreprise de menuiseries extérieures"},
      {type: "content", text: "Nous posons tous types de menuiseries portes, fenêtres, baies coulissantes, portes de garages, volets, en bois, aluminium, PVC."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/CLEANmenuiserie.jpg?alt=media&token=37284d4a-b02a-4321-85ac-2657409ce6f3" },
      {type: "content", text: "Nous pouvons poser en neuf ou en rénovation en remplacement d'anciennes menuiseries."},
      {type: "content", text: "Nous pouvons mener à bien aussi des dossiers Aéroports de Paris."},
      {type: "content", text: "Nous posons aussi tous types de menuiseries intérieures manufacturées type escaliers, claustras, châssis vitré, cloisons bois, etc."},
      {type: "content", text: "La société ABS Entreprise est à même aussi de construire votre maison, votre agrandissement ou votre surélévation en ossature bois, technique très actuelle, solution légère et rapide de construction."},
      {type: "content", text: "Nous posons également tous types de bardage, bois, PVC ou autres."},
    ],
    terrassement : [
      {type: "title", text: "Entreprise de terrassement et assainissement, à Argenteuil, Saint-Denis"},
      {type: "content", text: "Equipé mécaniquement, ABS entreprise est en mesure de réaliser tous types de travaux d'assainissement que ce soit sur l'habitat individuel ou sur du petit collectif, n'hésiter pas à nous consulter chaque cas est différent."},
      {type: "picture", src: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/terrassement.JPG?alt=media&token=48060607-0719-4112-8a22-2cbea3931cb7" },
      {type: "content", text: "Que ce soit une mise aux normes sur ancienne installation, une séparation des réseaux, une création de réseaux sur du neuf ou de nouveaux lots, nous sommes à même de suivre le cahier des charges émis par les services de la ville ou de la communauté de communes si il y en a un."},
    ]
  };

  const Content = ({ text }) => ( <Typography variant="body1" component="h2" gutterBottom className={classes.content}>{text}</Typography> );

  const Picture = ({ pic, name }) => ( <img className={classes.picture} src={pic} alt={name} /> )

  return (
    <div className={classes.root}>
      {content[article].map((item, i) => (
        i < 1 ? <Banner key={i} text={item.text} identifier={article} /> 
        : item.type === "picture" ? <Picture key={i} pic={item.src} name={i} />
        : item.type === "title" 
          ? <Subtitle key={i} text={item.text} />
          : <Content key={i} text={item.text} />
      ))}
    </div>
  )
};

export default pure(Articles)
