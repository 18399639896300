import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PhoneIcon from '@material-ui/icons/Phone';
import logo from './logoCropped.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  menuButton: {
    marginRight: "auto",
    color: `${theme.palette.secondary.main}`
  },
  phoneButton: {
    marginLeft: "auto",
    color: `${theme.palette.secondary.main}`
  }
}));

const BarMobile = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton 
            edge="start" 
            className={classes.menuButton} 
            color="inherit" 
            aria-label="Menu"
            onClick={() => props.changeDrawer("drawer")}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} alt="abs" className="mobile-logo" />
          <a href="callto:0134287865" className={classes.phoneButton} >
            <IconButton 
              edge="end" 
              
              color="inherit" 
              aria-label="Menu"
            >
                <PhoneIcon />
            </IconButton>
          </a>
        </Toolbar>
      </AppBar>
    </div>
  );
}
export default BarMobile;