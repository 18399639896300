import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import fb from "./firebase";
import Banner from './Banner'
import Subtitle from './Subtitle'


const db = fb.database();


export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "", 
      phone: "", 
      email: "",
      address: "", 
      zip: "", 
      city: "",
      message: "",
      messageSent: false,
    }
  };

  handleChange = (value, item) => {
    const state = Object.assign({}, this.state, {[item]: value})
    this.setState(state)
  };

  saveMessage = (e) => {
    e.preventDefault();
    return db.ref('/messages').push(this.state)
    .then((key) => {
      const state = Object.assign({}, this.state, {messageSent: true})
      this.setState(state)
    })
    .catch(e => {console.log(e)})
  }

  closeAlert = () => {
    console.log("ref saved")
    const state = Object.assign({}, this.state, {messageSent: false})
    this.setState(state)
  }

  render () {
    const { name, phone, email, address, zip, city, message, messageSent } = this.state;

    const style = {
      margin: "20px auto"
    }

    return (
      <div className="contact-container container">
        <form className="contact-form" onSubmit={this.saveMessage} >
          <Banner identifier="contact" text="Contact, information" />
          <Subtitle text="Laissez un message" />
          <TextField
            label="Nom complet"
            value={name}
            onChange={(e) => this.handleChange(e.target.value, 'name')}
            margin="normal"
            fullWidth
          />
          <div className="form-container">
            <TextField
              label="Téléphone"
              value={phone}
              type="number"
              onChange={(e) => this.handleChange(e.target.value, 'phone')}
              margin="normal"
            />
            <TextField
              label="Email"
              value={email}
              type="email"
              onChange={(e) => this.handleChange(e.target.value, 'email')}
              margin="normal"
            />
          </div>
          <TextField
            label="Adresse"
            value={address}
            onChange={(e) => this.handleChange(e.target.value, 'address')}
            margin="normal"
            fullWidth
          />
          <div className="form-container">
            <TextField
              label="Code postal"
              value={zip}
              type="number"
              onChange={(e) => this.handleChange(e.target.value, 'zip')}
              margin="normal"
            />
            <TextField
              label="Ville"
              value={city}
              onChange={(e) => this.handleChange(e.target.value, 'city')}
              margin="normal"
            />
          </div>
          <TextField
            label="Votre message"
            value={message}
            onChange={(e) => this.handleChange(e.target.value, 'message')}
            margin="normal"
            fullWidth
            multiline
          />
          <Button 
            variant="contained" 
            color="secondary" 
            style={style}
            /*className={classes.button}*/
            onClick={this.saveMessage}
          >
            envoyer
          </Button>
          <div>
          <Dialog
            open={messageSent}
            keepMounted
            onClose={this.closeAlert}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">Votre message a été envoyé !</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Nous vous recontactons dès que possible
              </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={this.closeAlert} color="secondary" >
              OK
            </Button>
          </DialogActions>
          </Dialog>
        </div>
        </form>
      </div>
    )
  }
}