import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
  subtitle: {
    fontSize: "1.75rem",
    marginTop: 20,
    fontWeight: 700,
  },
  bar: {
    width: 70,
    border: "solid rgb(0, 144, 67) 3px",
    margin: "10px 0px 20px",
  },
}));

const Subtitle = (props) => {
  const classes = useStyles();
  const { text } = props;

  return (
    <div> 
      <Typography variant="h6" component="h2" color="secondary" className={classes.subtitle}  gutterBottom>{text}</Typography>
      <div className={classes.bar}></div> 
    </div> 
  );
}

export default Subtitle;
