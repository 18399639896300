import React from 'react';
import pure from 'recompose/pure';

const Accueil = props => (
  <div className="accueil">
    <div className="section-header">ABS ENTREPRISE DE BÂTIMENT À GROSLAY, VAL-D'OISE</div>
    <div className="section-text">
    La société ABS entreprise de bâtiment créée en 1994 est situé à GROSLAY dans l'Est du val d'oise. Nous oeuvrons dans le domaine de l'habitat. Notre domaine d'activité est le bâtiment au sens large que ce soit en rénovation, construction, agrandissement, surélévation, aménagement, etc.
    </div>
    <div className="section-title">DU PERSONNEL COMPÉTENT ET SPÉCIALISÉ</div>
    <div className="section-text">Fort de personnel compétent dans chaque corps de métier avec pour certains une grande polyvalence nous sommes capables de mener n'importe quel projet à terme en commençant par le gros oeuvre ou la démolition s'il le faut et pour finir par le second oeuvre : les peintures, les revêtements de sol et la décoration. Nos équipes forte d'un encadrement solide et compétent oeuvrent vers l'essentiel, votre satisfaction. Confiez-nous votre projet du démarrage à la réception et vos délais deviendront nos délais. Dans tous les cas, votre entreprise de bâtiment à Groslay (proche de Saint-Denis et Argenteuil)</div>
    <div className="section-title">NOS DOMAINES DE COMPÉTENCES</div>
    <div className="section-text">Depuis plus de 20 ans basé à Groslay dans l'est du 95 non loin d'Argenteuil et Saint-Denis nous oeuvrons dans les domaines très complexes du tous corps d'états et nous travaillons sur tout type de projets, une construction clefs en main, une toiture à remplacer, un ravalement sur du battit ancien à refaire, une salle de bains à rénover, une clôture à réaliser, une chaudière à remplacer, des combles, une cave, un sous-sol à aménager, une maison à restructurer, une rénovation électrique d'un appartement, d'un pavillon, etc.. Voilà le type de projets que nous sommes capables de réaliser.</div>
    <div className="section-text">Pour toutes demandes d'informations complémentaires, n'hésitez pas à contacter votre entreprise de bâtiment intervenant à Argenteuil, Saint-Denis mais aussi dans les villes voisines.</div>
  </div>
);

export default pure(Accueil)
