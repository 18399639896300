import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import BarMobile from './BarMobile'
import BarDesktop from './BarDesktop'
import MenuMobile from './MenuMobile'
import Accueil from './Accueil'
import Articles from './Articles'
import Access from './Access'
import ContactForm from './ContactForm';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import Typography from '@material-ui/core/Typography';
import fb from "firebase";


const theme = {
  palette: {
    primary: {
      main: "rgb(255, 255, 255)"
      //contrastText: "rgb(0, 144, 67)",
    },
    secondary: {
      main: "rgb(0, 144, 67)",
    }, 
  },
  overrides: {
    MuiTypography: {
      h5: { marginTop: "20px" },
      h6: { marginTop: "15px" }
    }
  }
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: false,
      menu1open: false,
      showMore: false,
      article: "construction",
      screenType: 'desktop'
    };
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.updateWidth)
    const w = window;
    const cheating = { target: {innerWidth: w.innerWidth} }
    this.updateWidth(cheating)
  }

  updateWidth = ({target}) => {
    const width = target.innerWidth;
    let screenType;
    if (width > 599 && this.state.screenType === "mobile") {
      screenType = "desktop";
    } else if (width < 600 && this.state.screenType === "desktop") {
      screenType = "mobile";
    }
    if (screenType) {
      const state = Object.assign({}, this.state, { screenType })
      this.setState(state);
    }
  }


  changeDrawer = (toChange) => {
    let state;
    if (toChange === "drawer" && !this.state.showMore) {
      state = Object.assign({}, this.state, {drawerOpen: !this.state.drawerOpen})
    } else if (toChange === "showMore") {
      state = Object.assign({}, this.state, {showMore: !this.state.showMore, menu1open: false})
    } else if (toChange === "menu1") {
      state = Object.assign({}, this.state, {menu1open: !this.state.menu1open, showMore: false})
    }
    this.setState(state)
  };

  changeArticle = (article) => {
    const state = Object.assign({}, this.state, {article, /*drawerOpen: false,*/ menu1open: false, showMore: false})
    this.setState(state)
  };


  render () {
    const { drawerOpen, showMore, article, menu1open, screenType } = this.state;

    return (
      <Router>
        <MuiThemeProvider theme={createMuiTheme(theme)}>   
          <div className="App">
            {
              screenType === "desktop"
              ? <BarDesktop 
                menu1open={menu1open} 
                showMore={showMore} 
                changeDrawer={this.changeDrawer} 
                changeArticle={this.changeArticle}
                //changeShowMore={this.changeShowMore}
              />
              : <BarMobile drawerOpen={drawerOpen} changeDrawer={this.changeDrawer} />
            }
              <MenuMobile 
                drawerOpen={drawerOpen}
                showMore={showMore}
                changeDrawer={this.changeDrawer}
                changeShowMore={this.changeShowMore}
                changeArticle={this.changeArticle}
              />
              <div className="app-content">
                <Switch>
                  <Route exact path="/" render={(props) => <Articles {...props} article={article} />} />
                  <Route exact path="/articles" render={(props) => <Articles {...props} article={article} />} />
                  <Route exact path="/contact" component={ContactForm} />
                  <Route exact path="/acces" component={Access} />
                </Switch>
              </div>
              <div className="footer">
                <Typography className="typo" variant="body1" >ABS Entreprises © 2019</Typography>
                <Typography className="typo" variant="body1" >|</Typography>
                <Typography className="typo" variant="body1" >Email : contact@abs-entreprise.com</Typography>
                <Typography className="typo" variant="body1" >|</Typography>
                <Typography className="typo" variant="body1" >Téléphone : 01 34 28 78 65</Typography>
              </div>
          </div>
        </MuiThemeProvider>
      </Router>
    );
  }
}

export default App;
