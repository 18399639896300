import React from 'react';
import pure from 'recompose/pure';
//import GoogleApiWrapper from './GoogleApiWrapper';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AlarmIcon from '@material-ui/icons/Alarm';
import EventIcon from '@material-ui/icons/Event';
import PrintIcon from '@material-ui/icons/Print';
import PhoneIcon from '@material-ui/icons/Phone';
import ParkingIcon from '@material-ui/icons/LocalParking';
import LocationIcon from '@material-ui/icons/LocationOn';
import DraftsIcon from '@material-ui/icons/Drafts';
//import GoogleApiWrapper from './Map';
import Banner from './Banner'
import Subtitle from './Subtitle';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));


const Access = (props) => {
  const classes = useStyles();

  const ListItemLink = (props) => {
    return <ListItem button component="a" {...props} />;
  }

  const SimpleItem = (props) => {
    return (
      <ListItem>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.text} />
      </ListItem>
    )
  }

  return (
    <div className={classes.root}>
      <Banner identifier="access" text="Informations pratiques" />
        <Subtitle text="Coordonnées" />
        <List component="nav">
          <ListItemLink href="mailto:contact@abs-entreprise.com">
            <ListItemIcon><DraftsIcon /></ListItemIcon>
            <ListItemText primary="contact@abs-entreprise.com" />
          </ListItemLink>
          <ListItemLink href="callto:0134287865">
            <ListItemIcon><PhoneIcon /></ListItemIcon>
            <ListItemText primary="01 34 28 78 65" />
          </ListItemLink>
          <SimpleItem icon={<PrintIcon />} text="01 34 28 72 86" />
          <ListItemLink href="https://www.google.com/maps/place/6+Rue+de+Sarcelles,+95410+Groslay/@48.9838969,2.360629,17z/data=!3m1!4b1!4m5!3m4!1s0x47e6699bafd48ff1:0xfca96c59ad453929!8m2!3d48.9838969!4d2.3628177" target="blank">
            <ListItemIcon><LocationIcon /></ListItemIcon>
            <ListItemText primary="6 rue de Sarcelles, 95410 GROSLAY" />
          </ListItemLink>
        </List>
        <Subtitle text="Horaires" />
        <List component="nav">
          <SimpleItem icon={<AlarmIcon />} text="Lundi au jeudi : 8h30-12h et 13h-17h30" />
          <SimpleItem icon={<AlarmIcon />} text="Vendredi : 8h30-12h et 13h30-16h30" />
          <SimpleItem icon={<EventIcon />} text="Visites sur rendez-vous" />
          <SimpleItem icon={<ParkingIcon />} text="Sur place" />
        </List>

        
      {/*<div className="google-map" >
        <GoogleApiWrapper />
  </div>*/}
    </div>
  )
};

export default pure(Access);
  
