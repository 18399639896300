import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
  title: {
    fontSize: "1.75rem",
    fontWeight: 700,
    textTransform: "uppercase",
    paddingTop: "15px",
    margin: "40px auto",
    padding: "0 20px",
  },
  bannerContainer: {
    height: 280,
    width: "calc(100% + 40px)",
    overflow: "hidden",
    marginLeft: -20,
    zIndex: -1,
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: { 
      width: "calc(100vw - 15px)",
      marginLeft: "calc((865px - 100vw) / 2)",
    },
  },
}));

const Banner = (props) => {
  const classes = useStyles();
  const { text, identifier } = props;

  const banners = {
    construction: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/banners%2Fconstruction1.jpg?alt=media&token=a5fde7a2-d0e3-48e2-b248-51f9612ad831",
    renovation: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/banners%2Frenovation.jpg?alt=media&token=8f7dbb48-3877-4ffa-8eb7-88731f6f32a5",
    agrandissement: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/banners%2Fagrandissement.jpg?alt=media&token=137afaae-a64b-4688-90be-b8b58f119bae",
    surelevation: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/banners%2Fsurelevation.jpg?alt=media&token=afc4090c-d42f-4048-8896-22c22f87554f",
    combles: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/banners%2Fcombles.jpg?alt=media&token=c8d99840-8850-4024-88ef-a53a1e66678d",
    couverture: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/banners%2Fcouverture.jpg?alt=media&token=b3724e29-b81f-49ee-a534-d78ebbbc8d36",
    electricite: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/banners%2Fplomberie.jpg?alt=media&token=13f185dd-3b37-4904-b359-b09772905177",
    isolation: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/banners%2Fisolation.jpg?alt=media&token=f911eb9b-b1d9-4e72-8592-e456df65b336",
    menuiserie: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/banners%2Fmenuiserie.jpg?alt=media&token=07798770-fb20-4fc7-8dba-33faa8f45389",
    terrassement: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/banners%2Fterrassement.jpg?alt=media&token=5408a811-97af-4b8c-bbf4-76294ee1b895",
    access: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/banners%2Faccess.jpg?alt=media&token=58ae9824-8f11-4532-9305-8b921cedc168",
    contact: "https://firebasestorage.googleapis.com/v0/b/absentreprisefinal.appspot.com/o/banners%2Fcontact.jpg?alt=media&token=6bfb20bb-12d5-4eab-97c5-a05564a050aa",
  };

  const style = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${banners[identifier]})`,
  };

  return (
    <div className={classes.bannerContainer}  style={style}>    
      <Typography variant="h5" component="h2" color="primary" className={classes.title} gutterBottom>
        {text}
      </Typography> 
    </div>
  );
}

export default Banner;
