import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {NavLink} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from './logoCropped.png';
import sections from './menuSections'
import subSections from './menuSubSections'
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: "flex",
    [theme.breakpoints.down("sm")]: { display: "none" },
    [theme.breakpoints.up("sm")]: { display: "block" }
  },
  toolRoot: { height: "80px", },
  logo: {
    marginRight: "auto",
    height: "50px",
  },
  typo: { 
    color: `${theme.palette.secondary.main}`, margin: "0px 15px", cursor: "pointer",
    textTransform: "uppercase", fontSize: "1.1rem",
    "&:hover": { textDecoration: "underline" }
  },
  cardz: {zIndex: 1000},
  container: { display: "flex" },
  menuButton: { marginRight: "auto", },
  phoneButton: {
    marginLeft: "auto",
    color: 'inherit'
  }
}));

const BarDesktop = (props) => {
  const classes = useStyles();
  const { menu1open, showMore } = props;
  const anchorRef = React.useRef(null);
  const anchorRefMore = React.useRef(null);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense" className={classes.toolRoot}>
          <img src={logo} alt="abs" className={classes.logo} />

          <Typography 
            className={classes.typo}
            variant="body1" 
            component="h2" 
            //color="secondary" 
            ref={anchorRef}
            aria-owns={menu1open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={() => props.changeDrawer("menu1")}
          >
            Expertise ABS
          </Typography>
          <Popper open={menu1open} anchorEl={anchorRef.current} transition disablePortal className={classes.cardz}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper id="menu-list-grow">
                  <ClickAwayListener onClickAway={() => props.changeDrawer("menu1")}>
                    <MenuList>
                      {sections.map(item => (
                        <NavLink key={item.link} to="articles" activeClassName="selected" className="mobile-nav-link" onClick={(e) => props.changeArticle(item.link)}>
                            <MenuItem >{item.title}</MenuItem>
                        </NavLink>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Typography 
            className={classes.typo}
            variant="body1" 
            component="h2" 
            //color="secondary" 
            ref={anchorRefMore}
            aria-owns={showMore ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={() => props.changeDrawer("showMore")}
          >
            Activités complémentaires
          </Typography>
          <Popper open={showMore} anchorEl={anchorRefMore.current} transition disablePortal className={classes.cardz}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper id="menu-list-grow">
                  <ClickAwayListener onClickAway={() => props.changeDrawer("showMore")}>
                    <MenuList>
                      {subSections.map(item => (
                        <NavLink key={item.link} to="articles" activeClassName="selected" className="mobile-nav-link" onClick={(e) => props.changeArticle(item.link)}>
                          <MenuItem key={item.link} >{item.title}</MenuItem>
                        </NavLink>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <NavLink to={"/acces"} activeClassName="selected" className="mobile-nav-link" onClick={() => props.changeArticle(null)} >
            <Typography variant="body1" component="h2" className={classes.typo} >
              Accès
            </Typography>
          </NavLink>
          <NavLink to={"/contact"} activeClassName="selected" className="mobile-nav-link" onClick={() => props.changeArticle(null)}>
            <Typography variant="body1" component="h2" className={classes.typo} >
              Contact
            </Typography>
          </NavLink>
          
        </Toolbar>
      </AppBar>
    </div>
  );
}
export default BarDesktop;