import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

// Add the Firebase services that you want to use
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC3Mgomj4WOk8D69_qjUVkQf5RGAcR8vA8",
  authDomain: "absentreprisefinal.firebaseapp.com",
  databaseURL: "https://absentreprisefinal.firebaseio.com",
  projectId: "absentreprisefinal",
  storageBucket: "absentreprisefinal.appspot.com",
  messagingSenderId: "41322124781",
  appId: "1:41322124781:web:63da1ac584ebe4ad"
};

const fb = firebase.initializeApp(firebaseConfig);
export default fb;