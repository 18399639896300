import React from 'react';
import {NavLink} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import sections from './menuSections'
import subSections from './menuSubSections'

const useStyles = makeStyles( theme => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  list: {
    textDecoration: "none"
  },
  fullList: {
    width: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const MenuMobile = (props) => {
  const classes = useStyles();

  const delayClose = () => {
    setTimeout(() => {
      props.changeDrawer("drawer")
    }, 500)
  };

  const OneArticle = ({ title, link, customClass }) => (
    <NavLink 
      to="articles" 
      activeClassName="selected" 
      className="mobile-nav-link" 
      onClick={(e) => props.changeArticle(link)}
    >
      <ListItem button className={classes[customClass]}>
        {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
        <ListItemText primary={title} />
      </ListItem>
    </NavLink>
  );

  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      //onKeyDown={() => props.changeDrawer("drawer")}
      //onClick={() => props.changeDrawer("drawer")}
      //onKeyDown={() => delayClose()}
      onClick={() => delayClose()}
    >
      <List>
        {sections.map(obj => (
          <OneArticle key={obj.link} title={obj.title} link={obj.link} customClass="" />
        ))}
        <ListItem button >
          {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
          <ListItemText primary="Activités complémentaires" onClick={() => props.changeDrawer("showMore")} />
        </ListItem>
        <Collapse in={props.showMore} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subSections.map(obj => (
              <OneArticle key={obj.link} title={obj.title} link={obj.link} customClass="nested" />
            ))}
          </List>
        </Collapse>
      </List>
      <Divider />
      <List>
      <NavLink to={"/acces"} activeClassName="selected" className="mobile-nav-link" >
        <ListItem>
          <ListItemText primary="Accès" />
        </ListItem>
      </NavLink>
      <NavLink to={"/contact"} activeClassName="selected" className="mobile-nav-link" >
        <ListItem>
          <ListItemText primary="Contact" />
        </ListItem>
      </NavLink>
        
      </List>
    </div>
  );

  return (
    <Drawer 
      open={props.drawerOpen} 
      anchor="left" 
      className={classes.root}
    >
      {sideList('left')}
    </Drawer>
  );
}

export default MenuMobile;
